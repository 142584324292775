import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import CabinsFullWidth from "../components/cabinsFullWidth"
import ContactComponent from "../components/contact"

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <ContactComponent />
      <CabinsFullWidth/>
    </Layout>
  )
}

export default Contact
