import React from 'react'

const Map = () => {
  return (
        <div className="google-map-code">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2611.584411974999!2d-125.88874658416246!3d49.11353509066126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54899a0dde19829b%3A0x92c25ec0bc1cda58!2sGold%20Coast%20Retreat!5e0!3m2!1sen!2sca!4v1614788988578!5m2!1sen!2sca" 
            width="415" 
            height="500" 
            style={{
              border:"0",
              maxWidth: "415"
            }} 
            allowfullscreen="" 
            loading="lazy"
            title="Google Maps block with Gold Coast Retreat location"
          >
          </iframe>
        </div>
  )
}

export default Map